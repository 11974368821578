import React from "react";

import "../../scss/general.scss";
import "../../scss/colors.scss";
import "../../scss/fonts.scss";
import "./style.scss";

const CrWorldwide = () => (
  <svg
    fill="#fff"
    id="Ebene_1"
    className="cr-worldwide"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
  >
    <defs>
      <clipPath id="clip-path">
        <path d="M236.73,501.4c0-32.63,136.07-54.56,263.16-54.56s263.17,21.93,263.17,54.56S627,556,499.89,556,236.73,534,236.73,501.4m-4,0c0,32.85,117.36,58.58,267.18,58.58s267.18-25.73,267.18-58.58-117.35-58.57-267.18-58.57S232.71,468.56,232.71,501.4" />
      </clipPath>
      <clipPath id="clip-path-2">
        <path d="M272.89,570.66c0-20.77,91.21-43,227-43s227,22.24,227,43-91.22,43-227,43-227-22.24-227-43m-4,0c0,29.44,117.46,47,231,47s231-17.59,231-47-117.46-47-231-47-231,17.59-231,47" />
      </clipPath>
      <clipPath id="clip-path-3">
        <path d="M272.89,427.52c0-20.77,91.21-43,227-43s227,22.24,227,43-91.22,43-227,43-227-22.24-227-43m-4,0c0,29.44,117.46,47,231,47s231-17.59,231-47-117.46-47-231-47-231,17.59-231,47" />
      </clipPath>
      <clipPath id="clip-path-4">
        <path d="M372.32,375.58c0-13.53,51.26-28,127.57-28s127.57,14.48,127.57,28-51.26,28-127.57,28-127.57-14.48-127.57-28m-4,0c0,15.52,46.12,32,131.58,32s131.59-16.5,131.59-32c0-20.05-66.91-32-131.59-32s-131.58,12-131.58,32" />
      </clipPath>
      <clipPath id="clip-path-5">
        <path d="M372.32,620.3c0-13.52,51.26-28,127.57-28s127.57,14.48,127.57,28-51.26,28-127.57,28-127.57-14.48-127.57-28m-4,0c0,20,66.9,32,131.58,32s131.59-12,131.59-32c0-15.52-46.12-32-131.59-32s-131.58,16.5-131.58,32" />
      </clipPath>
      <clipPath id="clip-path-6">
        <path d="M402.46,499c0-83.62,43.71-151.65,97.43-151.65s97.43,68,97.43,151.65-43.71,151.65-97.43,151.65-97.43-68-97.43-151.65m-4,0c0,85.84,45.51,155.67,101.45,155.67S601.34,584.8,601.34,499,555.83,343.29,499.89,343.29,398.44,413.12,398.44,499" />
      </clipPath>
      <clipPath id="clip-path-7">
        <path d="M321.1,499.16c0-83.51,80.21-151.46,178.79-151.46s178.79,67.95,178.79,151.46-80.2,151.45-178.79,151.45S321.1,582.67,321.1,499.16m-4,0c0,85.72,82,155.47,182.81,155.47S682.7,584.88,682.7,499.16s-82-155.48-182.81-155.48S317.08,413.43,317.08,499.16" />
      </clipPath>
      <clipPath id="clip-path-8">
        <path d="M258.82,498.21c0-83.63,108.15-151.65,241.07-151.65S741,414.58,741,498.21,632.82,649.87,499.89,649.87s-241.07-68-241.07-151.66m-4,0c0,85.84,110,155.67,245.09,155.67S745,584.05,745,498.21,635,342.53,499.89,342.53,254.8,412.37,254.8,498.21" />
      </clipPath>
      <clipPath id="clip-path-9">
        <path d="M234.72,497.36c0-83.23,119-150.95,265.17-150.95s265.18,67.72,265.18,151-119,150.95-265.18,150.95S234.72,580.6,234.72,497.36m-4,0c0,85.45,120.75,155,269.19,155s269.19-69.52,269.19-155-120.75-155-269.19-155-269.19,69.51-269.19,155" />
      </clipPath>
    </defs>
    <rect
      x="591.17"
      y="311.63"
      width="44.36"
      height="12.57"
      transform="translate(173.04 850.26) rotate(-77.8)"
    />
    <polygon points="670.93 323.39 655.85 318.9 646.73 349.59 634.75 346.02 643.87 315.34 628.79 310.85 632.31 299.02 674.45 311.56 670.93 323.39" />
    <polygon points="686.21 349.41 681.08 313.63 694.09 319.49 696.69 338.7 697.89 339.23 714.06 328.47 727.77 334.64 697.6 354.54 691.93 367.14 680.54 362.02 686.21 349.41" />
    <polygon points="278.55 621.3 290.91 626.12 290.78 652.98 292.07 653.48 309.23 633.26 319.08 637.1 318.16 663.65 319.45 664.15 337.39 644.24 350.54 649.37 318.98 684.67 306.33 679.74 307.4 656.84 305.25 656 290.45 673.55 277.88 668.65 278.55 621.3" />
    <path d="M236.73,501.4c0-32.63,136.07-54.56,263.16-54.56s263.17,21.93,263.17,54.56S627,556,499.89,556,236.73,534,236.73,501.4m-4,0c0,32.85,117.36,58.58,267.18,58.58s267.18-25.73,267.18-58.58-117.35-58.57-267.18-58.57S232.71,468.56,232.71,501.4" />
    <g class="cls-1">
      <rect x="232.71" y="442.83" width="534.37" height="117.15" />
    </g>
    <path d="M272.89,570.66c0-20.77,91.21-43,227-43s227,22.24,227,43-91.22,43-227,43-227-22.24-227-43m-4,0c0,29.44,117.46,47,231,47s231-17.59,231-47-117.46-47-231-47-231,17.59-231,47" />
    <g class="cls-2">
      <rect x="268.87" y="523.63" width="462.04" height="94.06" />
    </g>
    <path d="M272.89,427.52c0-20.77,91.21-43,227-43s227,22.24,227,43-91.22,43-227,43-227-22.24-227-43m-4,0c0,29.44,117.46,47,231,47s231-17.59,231-47-117.46-47-231-47-231,17.59-231,47" />
    <g class="cls-3">
      <rect x="268.87" y="380.49" width="462.04" height="94.05" />
    </g>
    <path d="M372.32,375.58c0-13.53,51.26-28,127.57-28s127.57,14.48,127.57,28-51.26,28-127.57,28-127.57-14.48-127.57-28m-4,0c0,15.52,46.12,32,131.58,32s131.59-16.5,131.59-32c0-20.05-66.91-32-131.59-32s-131.58,12-131.58,32" />
    <g class="cls-4">
      <rect x="368.31" y="343.55" width="263.17" height="64.05" />
    </g>
    <path d="M372.32,620.3c0-13.52,51.26-28,127.57-28s127.57,14.48,127.57,28-51.26,28-127.57,28-127.57-14.48-127.57-28m-4,0c0,20,66.9,32,131.58,32s131.59-12,131.59-32c0-15.52-46.12-32-131.59-32s-131.58,16.5-131.58,32" />
    <g class="cls-5">
      <rect x="368.31" y="588.28" width="263.17" height="64.05" />
    </g>
    <path d="M402.46,499c0-83.62,43.71-151.65,97.43-151.65s97.43,68,97.43,151.65-43.71,151.65-97.43,151.65-97.43-68-97.43-151.65m-4,0c0,85.84,45.51,155.67,101.45,155.67S601.34,584.8,601.34,499,555.83,343.29,499.89,343.29,398.44,413.12,398.44,499" />
    <g class="cls-6">
      <rect x="398.44" y="343.29" width="202.9" height="311.34" />
    </g>
    <path d="M321.1,499.16c0-83.51,80.21-151.46,178.79-151.46s178.79,67.95,178.79,151.46-80.2,151.45-178.79,151.45S321.1,582.67,321.1,499.16m-4,0c0,85.72,82,155.47,182.81,155.47S682.7,584.88,682.7,499.16s-82-155.48-182.81-155.48S317.08,413.43,317.08,499.16" />
    <g class="cls-7">
      <rect x="317.08" y="343.68" width="365.62" height="310.95" />
    </g>
    <path d="M258.82,498.21c0-83.63,108.15-151.65,241.07-151.65S741,414.58,741,498.21,632.82,649.87,499.89,649.87s-241.07-68-241.07-151.66m-4,0c0,85.84,110,155.67,245.09,155.67S745,584.05,745,498.21,635,342.53,499.89,342.53,254.8,412.37,254.8,498.21" />
    <g class="cls-8">
      <rect x="254.8" y="342.53" width="490.17" height="311.35" />
    </g>
    <path d="M234.72,497.36c0-83.23,119-150.95,265.17-150.95s265.18,67.72,265.18,151-119,150.95-265.18,150.95S234.72,580.6,234.72,497.36m-4,0c0,85.45,120.75,155,269.19,155s269.19-69.52,269.19-155-120.75-155-269.19-155-269.19,69.51-269.19,155" />
    <g class="cls-9">
      <rect x="230.7" y="342.4" width="538.38" height="309.93" />
    </g>
    <path d="M279.24,355.66c-5.34-11.91,1.73-25.94,16.51-32.56,13.23-5.94,26.91-3.34,33.36,6l-12.24,5.5c-3.69-2.58-9.77-2.74-16,0-8,3.6-12.26,10.37-9.84,15.77s10.28,6.73,18.31,3.13c6.19-2.78,10.11-7.43,10.64-11.9l12.25-5.49c2.65,11-4.51,22.95-17.74,28.89-14.78,6.63-29.95,2.58-35.29-9.33" />
    <path d="M349,324.88l11.5-3.26c4.53-1.29,6.7-3.24,6-5.53s-3.5-2.75-8-1.46L347,317.9Zm11.47,9.58-8.09,2.3,3.4,11.94-12.1,3.44L331.5,309.46l23.89-6.79c10.91-3.1,21.08,1.09,23.57,9.88,1.71,6-.72,12.11-5.93,16.33l14.6,10.76L373,343.79Z" />
    <polygon points="428.37 320.2 430.49 332.36 391.26 339.19 383.65 295.49 422.5 288.72 424.62 300.88 398.16 305.49 398.8 309.16 422.82 304.98 424.88 316.76 400.86 320.94 401.54 324.87 428.37 320.2" />
    <path d="M465.44,311.2,459,298.78l-1.85.14-4.6,13.24Zm6.22,11.91-23.55,1.75-2,5.95-14.23,1.06,15.82-45.66,17.78-1.32,22.34,42.83-13.24,1Z" />
    <polygon points="525.28 296.52 509.55 296.38 509.28 328.41 496.79 328.3 497.06 296.27 481.32 296.13 481.43 283.78 525.39 284.17 525.28 296.52" />
    <rect
      x="515.68"
      y="301.14"
      width="44.35"
      height="12.57"
      transform="translate(195.17 823.53) rotate(-86.17)"
    />
    <polygon points="551.8 285.57 565.09 287.41 573.47 319.07 574.77 319.26 591.45 291.05 605.96 293.05 581.38 334.45 564.2 332.08 551.8 285.57" />
    <rect x="497.88" y="343.25" width="4.02" height="307.06" />
    <path d="M384.57,680c1.46-5.74-3.87-11.67-12.39-13.85s-16.05.49-17.51,6.22,3.88,11.67,12.4,13.84,16-.48,17.5-6.21M342.12,669.2c3.22-12.65,17.47-19.23,33.17-15.22s25.06,16.61,21.84,29.25-17.56,19.2-33.18,15.22-25.05-16.61-21.83-29.25" />
    <path d="M416.29,683.49l11.83,1.8c4.65.72,7.43-.16,7.79-2.51s-2.05-4-6.7-4.67l-11.82-1.8ZM422.75,697l-8.32-1.28L412.57,708l-12.44-1.9,6.69-43.87L431.38,666c11.21,1.71,18.72,9.76,17.35,18.78-1,6.17-5.7,10.72-12.19,12.39L445.36,713l-15-2.3Z" />
    <polygon points="489.23 703.54 488.39 715.85 452.54 713.42 455.54 669.18 468.08 670.03 465.92 701.96 489.23 703.54" />
    <path d="M531.6,693.11c-.14-5.59-6.66-9.66-15.14-9.44l-8.4.22.5,19.66,8.41-.22c8.48-.22,14.77-4.63,14.63-10.22m13-.34c.32,12.66-11.23,22.47-26.89,22.89l-21.36.56-1.14-44.33,21.36-.57c15.66-.41,27.7,8.79,28,21.45" />
    <polygon points="540.42 671.92 553.52 669.79 567.05 692.98 568.42 692.76 572.93 666.63 583.36 664.93 596.06 688.26 597.43 688.04 602.78 661.77 616.71 659.51 607.45 705.95 594.05 708.13 583.34 687.86 581.05 688.23 577.22 710.87 563.9 713.03 540.42 671.92" />
    <rect
      x="624.29"
      y="654.65"
      width="12.58"
      height="44.36"
      transform="translate(-161.85 199.56) rotate(-15.97)"
    />
    <path d="M678.56,659.87c-2.06-5.21-9.58-6.8-17.47-3.67l-7.82,3.09,7.25,18.29,7.81-3.09c7.89-3.13,12.29-9.42,10.23-14.62m12.05-4.78c4.66,11.78-2.81,25-17.37,30.73l-19.87,7.87L637,652.44l19.87-7.86c14.56-5.76,29-1.26,33.7,10.51" />
    <polygon points="736.01 640.74 742.1 651.48 707.46 671.1 685.59 632.48 719.9 613.05 725.98 623.8 702.62 637.03 704.46 640.27 725.67 628.26 731.57 638.67 710.35 650.69 712.32 654.15 736.01 640.74" />
    <path d="M174.31,510.86v8.46H147.6V512.6l15.29-13.78c1.62-1.45,4.09-3.86,4.09-6S164.63,489,161,489s-6.1,1.62-6.1,3.92h-7.12c0-7.34,5.66-12.88,13.22-12.88s13.05,5.32,13.05,12.37c0,4.82-2.8,8.07-5.32,10.48l-8.29,8Z" />
    <path d="M178.68,500c0-11.82,6.27-20,15.23-20s15.29,8.17,15.29,20-6.27,20-15.29,20S178.68,511.82,178.68,500Zm23.41,0c0-6.72-3.3-11.37-8.18-11.37-4.7,0-8.12,4.65-8.12,11.37s3.42,11.37,8.12,11.37C198.79,511.37,202.09,506.72,202.09,500Z" />
    <path d="M817.28,511.2v8.46H790.57v-6.72l15.28-13.78c1.63-1.46,4.09-3.86,4.09-6.05s-2.35-3.81-5.93-3.81-6.11,1.63-6.11,3.92h-7.11c0-7.33,5.66-12.88,13.22-12.88s13,5.32,13,12.38c0,4.82-2.8,8.06-5.32,10.47l-8.28,8Z" />
    <path d="M838.89,481v38.64H832V490.82l-10.31,5v-9.35l11-5.43Z" />
  </svg>
);

export default CrWorldwide;
