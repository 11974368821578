import React from "react";
import { siteMetadata } from "../../gatsby-config";
import { Link } from "gatsby";

import Logo from "../components/logo";
import SocialLinks from "../components/social-links";
import CrWorldwide from "../components/cr-worldwide";
import Layout from "../components/layout";
import Meta from "../components/meta";
import Footer from "../components/footer";

import "../../node_modules/bootstrap/scss/bootstrap.scss";
import "../scss/general.scss";
import "../scss/colors.scss";
import "../scss/fonts.scss";
class Impressum extends React.Component {
  render() {
    const { location } = this.props;
    return (
      <>
        <Layout location={location}>
          <Meta
            site={siteMetadata}
            title="Impressum"
            description="Impressum"
            siteurl="impressum"
          />
          <main>
            <div className="position-relative main-hero">
              <Link href="/" to="/" className="link-no-styling">
                <Logo color="white" />
              </Link>
              <SocialLinks />
              <CrWorldwide />
              <div className="main-title text-center">
                <h4 className="text-white small">Creativity Rules 2.0</h4>
                <div className="text-fancy-gradient decoration-headline">
                  Jetzt live!
                </div>
                <h4 className="text-white small">26. & 27. März</h4>
                <h5 className="text-white">17-23 Uhr</h5>
              </div>
              <span className="main-title__arrow">↓</span>
            </div>

            <nav className="d-flex justify-content-around w-100 p-3">
              <Link className="nav-link" href="/" to="/">
                Das Festival
              </Link>
              <Link className="nav-link" href="/timetable" to="/timetable">
                Timetable
              </Link>
              <Link className="nav-link" href="/programm" to="/programm">
                Speakers & Acts
              </Link>
              <Link className="nav-link" href="/projects" to="/projects">
                Projekte
              </Link>
            </nav>

            <div className="content-block impressum">
              <div className="container">
                <div className="h3 mb-5">Impressum</div>
                <p>
                  Informationspflicht laut §5 E-Commerce Gesetz, §14
                  Unternehmensgesetzbuch, §63 Gewerbeordnung und
                  Offenlegungspflicht laut §25 Mediengesetz.
                </p>
                <p className="mb-0">Creativity Rules 2020</p>
                <p className="mb-0">Urstein Süd 1</p>
                <p>A-5412 Puch b. Hallein</p>
                <p className="mb-0">
                  E-Mail:{" "}
                  <a href="mailto:office@creativity-rules.at">
                    office@creativity-rules.at
                  </a>
                </p>
                <p>
                  Web:{" "}
                  <a href="www.creativity-rules.at">www.creativity-rules.at</a>
                </p>
                <p>
                  Eine Veranstaltung in Kooperation mit der Fachhochschule
                  Salzburg.
                </p>
                <div className="h4 mt-5 mb-4">EU-Streitschlichtung</div>
                <p>
                  Gemäß Verordnung über Online-Streitbeilegung in
                  Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie
                  über die Online-Streitbeilegungsplattform (OS-Plattform)
                  informieren. Verbraucher haben die Möglichkeit, Beschwerden an
                  die OnlineStreitbeilegungsplattform der EU zu richten:{" "}
                  <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a>.
                  Sie können allfällige Beschwerde auch an die oben angegebene
                  E-Mail-Adresse richten.
                </p>
                <div className="h4 mt-5 mb-4">
                  Haftung für Inhalte dieser Webseite
                </div>
                <p>
                  Wir entwickeln die Inhalte dieser Webseite ständig weiter und
                  bemühen uns korrekte und aktuelle Informationen
                  bereitzustellen. Leider können wir keine Haftung für die
                  Korrektheit aller Inhalte auf dieser Webseite übernehmen,
                  speziell für jene die seitens Dritter bereitgestellt wurden.
                </p>
                <div className="h4 mt-5 mb-4">
                  Haftung für Links auf dieser Webseite
                </div>
                <p>
                  Unsere Webseite enthält Links zu anderen Webseiten für deren
                  Inhalt wir nicht verantwortlich sind. Haftung für verlinkte
                  Websites besteht laut § 17 ECG für uns nicht, da wir keine
                  Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns
                  solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind
                  und wir Links sofort entfernen würden, wenn uns
                  Rechtswidrigkeiten bekannt werden. Wenn Ihnen rechtswidrige
                  Links auf unserer Website auffallen, bitten wir Sie uns zu
                  kontaktieren, Sie finden die Kontaktdaten im Impressum.
                </p>
                <div className="h4 mt-5 mb-4">Urheberrechtshinweis</div>
                <p>
                  Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
                  unterliegen dem Urheberrecht. Falls notwendig, werden wir die
                  unerlaubte Nutzung von Teilen der Inhalte unserer Seite
                  rechtlich verfolgen.
                </p>
                <div className="h4 mt-5 mb-4">Bildernachweis</div>
                <p className="mb-0">
                  Die Bilder, Fotos und Grafiken auf dieser Webseite sind
                  urheberrechtlich geschützt.
                </p>
                <p className="mb-0">
                  Die Bilderrechte liegen bei den folgenden Personen:
                </p>
                <p>Andreas Cantucci, Lukas Pattermann, Johanna Wicht</p>
                <div className="h3 my-5">Datenschutzerklärung</div>
                <p>
                  Der Schutz Ihrer persönlichen Daten ist uns ein besonderes
                  Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf
                  Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In
                  diesen Datenschutzinformationen informieren wir Sie über die
                  wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer
                  Website.{" "}
                </p>
                <p>
                  Beim Besuch unserer Webseite wird Ihre IP-Adresse, Beginn und
                  Ende der Sitzung für die Dauer dieser Sitzung erfasst. Dies
                  ist technisch bedingt und stellt damit ein berechtigtes
                  Interesse iSv Art 6 Abs 1 lit f DSGVO dar. Soweit im Folgenden
                  nichts anderes geregelt wird, werden diese Daten von uns nicht
                  weiterverarbeitet.
                </p>
                <div className="h4 mt-5 mb-4">Verwendung von Cookies</div>
                <p>
                  Wir verwenden Cookies, um den Besuch unserer Website attraktiv
                  zu gestalten und die Nutzung bestimmter Funktionen zu
                  ermöglichen. Hierbei handelt es sich um kleine Textdateien,
                  die auf Ihrem Rechner abgelegt werden. Die Cookies verbleiben
                  auf Ihrem Rechner und ermöglichen uns, Ihren Rechner bei Ihrem
                  nächsten Besuch wieder zu erkennen (Langzeit-Cookies). Diese
                  Cookies dienen dazu die vom Besucher getroffenen individuellen
                  Auswahlen (z.B. die in einem Warenkorb abgelegten Artikel) zu
                  speichern, um sie dann beim neuerlichen Aufruf der jeweiligen
                  Site im hinterlassenen Zustand wiederherzustellen. Wir
                  verwenden Cookies für die Erstellung einer
                  nichtpersonalisierten Statistik. Sie können Ihren Browser so
                  einstellen, dass Sie über das Setzen von Cookies informiert
                  werden, von Fall zu Fall über die Annahme entscheiden oder die
                  Annahme von Cookies grundsätzlich ausschließen. Bei der
                  Nichtannahme von Cookies kann die Funktionalität unserer
                  Website eingeschränkt sein. Unter Hilfe in Ihrem Browser
                  finden Sie eine Beschreibung, wie Sie die gängigen Browser
                  entsprechend einstellen und Cookies annehmen oder ablehnen
                  können.
                </p>
                <div className="h4 mt-5 mb-4">Google Analytics</div>
                <p>
                  Diese Website benutzt Google Analytics, einen Webanalysedienst
                  der Google Inc. („Google“). Google Analytics verwendet sog.
                  „Cookies“, Textdateien, die auf Ihrem Computer gespeichert
                  werden und die eine Analyse der Benutzung der Website durch
                  Sie ermöglichen. Die durch den Cookie erzeugten Informationen
                  über Ihre Benutzung dieser Website werden in der Regel an
                  einen Server von Google in den USA übertragen und dort
                  gespeichert. Im Falle der Aktivierung der IP Anonymisierung
                  auf dieser Webseite, wird Ihre IP-Adresse von Google jedoch
                  innerhalb von Mitgliedstaaten der Europäischen Union oder in
                  anderen Vertragsstaaten des Abkommens über den Europäischen
                  Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die
                  volle IP-Adresse an einen Server von Google in den USA
                  übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
                  Website wird Google diese Informationen benutzen, um Ihre
                  Nutzung der Website auszuwerten, um Reports über die
                  Websiteaktivitäten zusammenzustellen und um weitere mit der
                  Websitenutzung und der Internetnutzung verbundene
                  Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.
                  Die im Rahmen von Google Analytics von Ihrem Browser
                  übermittelte IP-Adresse wird nicht mit anderen Daten von
                  Google zusammengeführt. Sie können die Speicherung der Cookies
                  durch eine entsprechende Einstellung Ihrer Browser-Software
                  verhindern; wir weisen Sie jedoch darauf hin, dass Sie in
                  diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
                  Website vollumfänglich werden nutzen können. Sie können
                  darüber hinaus die Erfassung der durch das Cookie erzeugten
                  und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
                  IP-Adresse) an Google sowie die Verarbeitung dieser Daten
                  durch Google verhindern, indem sie das unter dem folgenden
                  Link verfügbare Browser-Plugin herunterladen und installieren:
                  <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
                    http://tools.google.com/dlpage/gaoptout?hl=de
                  </a>
                </p>
                <p>
                  Datensicherheit Ihre Vertragsdaten werden verschlüsselt
                  mittels SSL Verfahren über das Internet übertragen. Wir
                  sichern unsere Website und sonstigen Systeme durch technische
                  und organisatorische Maßnahmen gegen Verlust, Zerstörung,
                  Zugriff, Veränderung oder Verbreitung Ihrer Daten durch
                  unbefugte Personen.
                </p>
                <div className="h4 mt-5 mb-4">Social Plugins</div>
                <p>
                  Auf unseren Webseiten verwenden wir teilweise auch Social
                  Plugins, bspw. solche der Sozialen Netzwerke Facebook,
                  Twitter, YouTube oder Google+. Diese werden oftmals in den USA
                  oder anderen Ländern außerhalb Österreichs und der EU
                  technisch betrieben und teilweise über nationale
                  Gesellschaften in der EU angeboten (s. zu den Anbietern die
                  Webseiten www.facebook.at, www.twitter.at, www.youtube.at oder
                  www.google.at). Die PlugIns sind bspw. mit dem Logo der
                  Firmen, Kommentarfeldern oder einem sog. »Like-«, »Gefällt
                  mir« oder »+1«-Button gekennzeichnet. Seite 17 von 19 Wenn Sie
                  eine Seite unseres Webauftritts in Ihrem Webbrowser öffnen,
                  die ein solches PlugIn beinhaltet, stellt Ihr Webbrowser eine
                  direkte Verbindung mit den Servern des jeweiligen Anbieters im
                  jeweiligen Land her. Durch die Einbindung der PlugIns erhält
                  der Anbieter zumindest die Information, dass Sie eine
                  bestimmte Seite unseres Webangebots besucht haben, ggf. auch
                  weitere Informationen, die Ihr Webbrowser oder Ihr benutztes
                  Gerät preisgeben. Der Inhalt des PlugIns wird von Ihrem
                  Webbrowser direkt vom Anbieter geladen und in unsere Webseite
                  eingebunden. Wenn Sie bei dem betreffenden Anbieter
                  registriert und eingeloggt sind, kann Ihr Besuch auch Ihrem
                  Nutzer-Konto zugeordnet werden. Wenn Sie mit einem PlugIn
                  interagieren, bspw. den »Like«-Button anklicken oder einen
                  Kommentar in einem Formularfeld abgeben, wird diese
                  Information zudem von Ihrem Browser direkt an den Anbieter
                  übermittelt und dort gespeichert und ggf. weiter benutzt und
                  veröffentlicht. Zweck und Umfang der beschriebenen
                  Datenerhebung und Nutzung sind v.a. Marketingmaßnahmen des
                  Anbieters. Einzelheiten dazu und zu Ihren Rechten und
                  Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre können
                  Sie direkt beim jeweiligen Anbieter in dessen
                  Datenschutzerklärung nachlesen. Wenn Sie nicht möchten, dass
                  ein Anbieter über unsere Webseiten Daten über Sie sammelt,
                  müssen Sie PlugIns in Ihrem Webbrowser deaktivieren. Wenn Sie
                  eine Verknüpfung mit einem ggf. bestehenden Nutzer-Konto
                  vermeiden wollen, müssen Sie sich vor Ihrem Besuch unserer
                  Webseiten ausloggen. Indem Sie unsere Webseiten besuchen,
                  erklären Sie sich mit den vorstehenden Bestimmungen
                  einverstanden
                </p>
                <div className="h4 mt-5 mb-4">
                  Links zu Webseiten anderer Anbieter
                </div>
                <p>
                  Unsere Webseiten können Links zu Webseiten anderer Anbieter
                  enthalten. Wir weisen darauf hin, dass diese
                  Datenschutzerklärung ausschließlich für die Webseite des
                  Creativity Rules gilt. Wir haben keinen Einfluss darauf und
                  kontrollieren nicht, dass andere Anbieter die geltenden
                  Datenschutzbestimmungen einhalten.
                </p>
                ‍<div className="h4 mt-5 mb-4">Rechtsbehelfsbelehrung</div>
                <p>
                  Ihnen stehen grundsätzlich die Rechte auf Auskunft,
                  Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit
                  und Widerspruch zu. Dafür wenden Sie sich an uns. Wenn Sie
                  glauben, dass die Verarbeitung Ihrer Daten gegen das
                  Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen
                  Ansprüche sonst in einer Weise verletzt worden sind, können
                  Sie sich bei der Aufsichtsbehörde beschweren. In Österreich
                  ist die Datenschutzbehörde zuständig.
                </p>
              </div>
            </div>
          </main>
          <Footer />
        </Layout>
      </>
    );
  }
}

export default Impressum;
