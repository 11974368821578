// import { graphql } from 'gatsby'
import React from "react";
import { siteMetadata } from "../../gatsby-config";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import "moment/locale/de";
import moment from "moment";

import Logo from "../components/logo";
import SocialLinks from "../components/social-links";
import CrWorldwide from "../components/cr-worldwide";

import Layout from "../components/layout";
import Meta from "../components/meta";
import Footer from "../components/footer";
import ProgrammItem from "../components/programm-item";

import "../../node_modules/bootstrap/scss/bootstrap.scss";
import "../scss/general.scss";
import "../scss/colors.scss";
import "../scss/fonts.scss";
import "../scss/index.scss";
import "../scss/programm.scss";

class Programm extends React.Component {
  render() {
    const { location, data } = this.props;
    const groups = data.allDirectusSpeakersWorkshop.group;
    return (
      <>
        <Layout location={location}>
          <Meta
            site={siteMetadata}
            title="Lineup"
            description="Lineup"
            siteurl="programm"
          />
          <main>
            <div className="position-relative main-hero">
              <Link href="/" to="/" className="link-no-styling">
                <Logo color="white" />
              </Link>
              <SocialLinks />
              <CrWorldwide />

              <div className="main-title text-center">
                <h4 className="text-white small">Creativity Rules 2.0</h4>
                <div className="text-fancy-gradient decoration-headline">
                  Jetzt live!
                </div>
                <h4 className="text-white small">26. & 27. März</h4>
                <h5 className="text-white">17-23 Uhr</h5>
              </div>
              <span className="main-title__arrow">↓</span>
            </div>

            <nav className="d-flex justify-content-around w-100 p-3">
              <Link className="nav-link" href="/" to="/">
                Das Festival
              </Link>
              <Link className="nav-link" href="/timetable" to="/timetable">
                Timetable
              </Link>
              <Link className="nav-link active" href="/programm" to="/programm">
                Speakers & Acts
              </Link>
              <Link className="nav-link" href="/projects" to="/projects">
                Projekte
              </Link>
            </nav>

            <div className="content-block">
              <div className="container">
                {groups.map((group, index) => {
                  const all = group.edges;
                  const date = moment(group.fieldValue).format(
                    "dddd, DoMMMM YYYY"
                  );
                  return (
                    <div key={date}>
                      {index === 0 ? (
                        <div className="h3">Speakers</div>
                      ) : (
                        <div className="h3 date-margin-top">
                          Speakers & Acts
                        </div>
                      )}
                      <div className="h3 headline-opacity date-margin-bottom">
                        {date}
                      </div>

                      {all.map(item => {
                        let formatedTime;
                        item.node.time
                          ? (formatedTime = moment(
                              item.node.time,
                              "HH:mm:ss"
                            ).format("HH:mm"))
                          : (formatedTime = null);

                        return (
                          <ProgrammItem
                            key={item.node.title}
                            title={item.node.title}
                            description={item.node.description}
                            additionalInfo={item.node.additional_info}
                            time={formatedTime}
                            imgUrl={item.node.image.data.full_url}
                          ></ProgrammItem>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </main>
          <Footer lineup="true" />
        </Layout>
      </>
    );
  }
}

export default Programm;

export const programmItems = graphql`
  {
    allDirectusSpeakersWorkshop(sort: { order: ASC, fields: [date, time] }) {
      group(field: date) {
        fieldValue
        edges {
          node {
            additional_info
            date(locale: "")
            description
            title
            time
            image {
              data {
                full_url
                url
              }
            }
          }
        }
      }
    }
  }
`;
