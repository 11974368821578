// import { graphql } from 'gatsby'
import React from "react";
import { Link } from "gatsby";
import CookieConsent from "react-cookie-consent";

import Logo from "../components/logo";
import SocialLinks from "../components/social-links";
import CrWorldwide from "../components/cr-worldwide";
import "../scss/general.scss";
import "../scss/colors.scss";
import "../scss/fonts.scss";
import "../scss/index.scss";
import "../scss/cookie-consent.scss";
import "../../node_modules/bootstrap/scss/bootstrap.scss";
import Footer from "../components/footer";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.acceptCookies = this.acceptCookies.bind(this);
    this.state = {
      isOpen: false,
      cookies: false
    };
    if (
      typeof window !== "undefined" &&
      window.sessionStorage.getItem("cookies-permitted")
    ) {
      this.state = {
        cookies: true
      };
    }
  }

  toggleOpen = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    return this.state;
  };

  acceptCookies = () => {
    this.setState({ cookies: true });
    if (typeof window !== "undefined" && window.sessionStorage) {
      window.sessionStorage.setItem("cookies-permitted", true);
    }

    return this.state;
  };

  render() {
    return (
      <>
        <main>
          <CookieConsent
            overlay
            location="bottom"
            buttonText="OK."
            declineButtonText="Decline"
            style={{
              background: "#000000",
              border: "2px solid white",
              right: 0,
              left: "unset",
              margin: "0 30px 30px 0",
              fontSize: "1.50rem",
              padding: "10px 15px"
            }}
            buttonStyle={{ background: "none", color: "white" }}
            cookieName="gatsby-gdpr-google-analytics"
          >
            Wir nutzen Cookies.
            <br />
            <a href="/impressum" className="text-white headline-opacity">
              Mehr erfahren
            </a>
          </CookieConsent>
          <div className="position-relative main-hero">
            <Link href="/" to="/" className="link-no-styling">
              <Logo color="white" />
            </Link>
            <SocialLinks />
            <CrWorldwide />
            <div className="main-title text-center">
              <h4 className="text-white small">Creativity Rules 2.0</h4>
              <div className="text-fancy-gradient decoration-headline">
                Jetzt live!
              </div>
              <h4 className="text-white small">26. & 27. März</h4>
              <h5 className="text-white">17-23 Uhr</h5>
            </div>
            <span className="main-title__arrow">↓</span>
          </div>

          <nav className="d-flex justify-content-around w-100 p-3">
            <Link className="nav-link active" href="/" to="/">
              Das Festival
            </Link>
            <Link className="nav-link" href="/timetable" to="/timetable">
              Timetable
            </Link>
            <Link className="nav-link" href="/programm" to="/programm">
              Speakers & Acts
            </Link>
            <Link className="nav-link" href="/projects" to="/projects">
              Projekte
            </Link>
          </nav>

          <div className="content-block">
            <div className="container">
              <div className="event-headline">
                <img
                  className="event-headline__logo"
                  src="/svg/cr-worldwide_bunt.svg"
                  alt="deco"
                ></img>
                <div className="h4">Creativity Rules 2.0</div>
                <h1 className="text-fancy-gradient mb-2 mb-md-4">
                  New RealiTV
                </h1>
              </div>

              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://player.twitch.tv/?channel=creativityrules&enableExtensions=true&parent=creativityrules2020.netlify.app&parent=creativity-rules.at&player=popout&volume=0.5"
                  title="Creativity Room Live Stream"
                  frameborder="0"
                  allowfullscreen="true"
                  scrolling="no"
                >
                  <a
                    href="https://www.twitch.tv/creativityrules/"
                    className="btn-livestream mt-md-5 mt-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="btn-livestream__icon"></span>
                    <span>Livestream</span>
                  </a>
                </iframe>
              </div>
            </div>
          </div>

          <div className="content-block">
            <div className="container">
              <div className="h3 mb-3">Join the party!</div>
              <h1 className="text-green mb-md-5 mb-3">Gather.town</h1>
              <a
                href="https://gather.town/app/QKMG3usdIIF7CFbb/CreativityRules2021"
                target="_blank"
                rel="noreferrer"
                className="d-flex justify-content-between text-uppercase p-3 link-no-styling mb-5 font-headline h4 festival-link"
              >
                Festivalgelände besuchen <span>&#10230;</span>
              </a>
              <div className="p1">
                <strong>
                  Der Vorteil eines digitalen Festivalgeländes? <br />
                </strong>
                <span>
                  Q&A Sessions mit Speaker*innen, die Ausstellung besuchen und
                  natürlich mit anderen Festivalbesucher*innen connecten.
                </span>
              </div>
            </div>
          </div>

          <div className="content-block">
            <div className="container">
              <h1 className="mb-md-5">Salzburgs Online Kreativ-Festival</h1>

              <div className="p1">
                Seit 2013 bietet das Salzburger Festivalformat{" "}
                <strong className="text-orange">Creativity Rules</strong>{" "}
                Einblicke in die kreative Welt von morgen. Ob{" "}
                <strong>Design, Film, Audio, Animation</strong> oder{" "}
                <strong>Game</strong>
                – das Festival präsentiert besondere Werke und Arbeiten von
                jungen Kreativen und lädt mit Speakern zur Erkundung neuer
                Realitäten ein.
                <br />
                <br />
                <strong>
                  {" "}
                  Dieses Jahr findet das Festival zu 100% digital statt – <br />
                  <span className="text-purple">kostenlos und überall.</span>
                </strong>
              </div>
            </div>
          </div>

          <div className="content-block">
            <div className="container">
              <div className="h3 mb-3">Unser Motto</div>
              <h1 className="text-green mb-md-5 mb-3">New Realities</h1>
              <div className="p1">
                Wir zelebrieren gemeinsam die
                <strong className="font-headline"> kreative </strong>
                und <strong className="font-headline"> digitale </strong>
                Zukunft. In Zeiten von{" "}
                <strong className="font-headline">
                  {" "}
                  Digitalität{" "}
                </strong> und{" "}
                <strong className="font-headline"> Social Distancing </strong>
                ermöglichen Technologie und Fortschritt die Existenz von
                <strong className="font-headline">
                  {" "}
                  neuen Plattformen{" "}
                </strong>{" "}
                und
                <strong className="font-headline">
                  {" "}
                  Kommunikationsmitteln.
                </strong>
              </div>
            </div>
          </div>

          <div className="content-block">
            <div className="container">
              <div className="h3 mb-3">Digitaler Raum</div>
              <h1 className="text-orange mb-md-5 mb-3">Gemeinsam erleben</h1>
              <div className="p1">
                Neben dem{" "}
                <strong className="font-headline"> Livestream </strong> bietet
                das Creativity Rules Festival auch einen digitalen Raum. In{" "}
                <strong className="font-headline"> gather.town </strong> wird es
                während des Festival Zeitraums möglich sein, neue und bekannte
                Gesichter auf eine innovative Art{" "}
                <strong className="font-headline"> online </strong> zu{" "}
                <strong className="font-headline"> treffen </strong> und mit
                ihnen zu{" "}
                <strong className="font-headline"> interagieren</strong>.
              </div>
            </div>
          </div>

          <div className="content-block">
            <div className="container">
              <h1 className="text-purple mb-md-5 mb-3 text ">Get real!</h1>
              <a
                href="mailto:office@creativity-rules.at"
                className="h2 font-paragraph"
              >
                office@creativity-rules.at
              </a>
            </div>
          </div>
          <Footer index="true" />
        </main>
      </>
    );
  }
}

export default Index;
