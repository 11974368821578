import React from "react";
import { siteMetadata } from "../../gatsby-config";
import { Link } from "gatsby";

import Logo from "../components/logo";
import SocialLinks from "../components/social-links";
import CrWorldwide from "../components/cr-worldwide";
import Layout from "../components/layout";
import Meta from "../components/meta";
import Footer from "../components/footer";

import "../scss/general.scss";
import "../scss/colors.scss";
import "../scss/fonts.scss";
import "../scss/index.scss";
import "../scss/timetable.scss";
import "../../node_modules/bootstrap/scss/bootstrap.scss";

class Timetable extends React.Component {
  render() {
    const { location } = this.props;
    return (
      <>
        <Layout location={location}>
          <Meta
            site={siteMetadata}
            title="Timetable"
            description="Timetable"
            siteurl="timetable"
          />
          <main>
            <div className="position-relative main-hero">
              <Link href="/" to="/" className="link-no-styling">
                <Logo color="white" />
              </Link>
              <SocialLinks />
              <CrWorldwide />
              <div className="main-title text-center">
                <h4 className="text-white small">Creativity Rules 2.0</h4>
                <div className="text-fancy-gradient decoration-headline">
                  Jetzt live!
                </div>
                <h4 className="text-white small">26. & 27. März</h4>
                <h5 className="text-white">17-23 Uhr</h5>
              </div>
              <span className="main-title__arrow">↓</span>
            </div>

            <nav className="d-flex justify-content-around w-100 p-3">
              <Link className="nav-link" href="/" to="/">
                Das Festival
              </Link>
              <Link
                className="nav-link active"
                href="/timetable"
                to="/timetable"
              >
                Timetable
              </Link>
              <Link className="nav-link" href="/programm" to="/programm">
                Speakers & Acts
              </Link>
              <Link className="nav-link" href="/projects" to="/projects">
                Projekte
              </Link>
            </nav>

            <div className="content-block">
              <div className="container">
                <div className="h3 headline-opacity mb-md-5 mb-3 pb-5">
                  Freitag, 26. März 2021
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5">17:00</div>
                  <div className="h2 font-headline">Welcome</div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5">17:05</div>
                  <div className="h2 font-headline">
                    <Link
                      href="/projects#Design & Web"
                      to="/projects#Design & Web"
                      className="text-green"
                    >
                      Design & Web
                    </Link>
                  </div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5">17:30</div>
                  <div className="h2 font-headline text-uppercase">
                    Esra Rotthoff
                  </div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5">18:20</div>
                  <div className="h2 font-headline">
                    <Link
                      href="/projects#Werbefilme"
                      to="/projects#Werbefilme"
                      className="text-purple"
                    >
                      Werbefilme
                    </Link>
                  </div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5">18:40</div>
                  <div className="h2 font-headline text-uppercase">
                    Rory sutherland
                  </div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5">19:30</div>
                  <div className="h2 font-headline text-uppercase">
                    Marc weigert
                  </div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5">20:15</div>
                  <div className="h2 font-headline">
                    <Link
                      href="/projects#Kurzfilme"
                      to="/projects#Kurzfilme"
                      className="text-purple"
                    >
                      Kurzfilme
                    </Link>
                  </div>
                </div>

                <div className="h3 headline-opacity mb-md-5 mb-3 py-5">
                  Samstag, 27. März 2021
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5">17:00</div>
                  <div className="h2 font-headline">Welcome</div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5">17:05</div>
                  <div className="h2 font-headline">
                    <Link
                      href="/projects#Game"
                      to="/projects#Game"
                      className="text-orange"
                    >
                      Game
                    </Link>
                  </div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5">17:30</div>
                  <div className="h2 font-headline text-uppercase">
                    Robin Hunicke
                  </div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5">18:20</div>
                  <div className="h2 font-headline">
                    <Link
                      href="/projects#Installationen"
                      to="/projects#Installationen"
                      className="text-green"
                    >
                      Installationen
                    </Link>
                  </div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5">18:40</div>
                  <div className="h2 font-headline text-uppercase">
                    <Link
                      href="/projects#Depart"
                      to="/programm#Depart"
                      className="text-white"
                    >
                      Depart
                    </Link>
                  </div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5">19:30</div>
                  <div className="h2 font-headline">
                    <Link
                      href="/projects#Kenji Araki"
                      to="/programm#Kenji Araki"
                      className="text-white"
                    >
                      Kenji Araki
                    </Link>
                  </div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5 hidden">19:30</div>
                  <div className="h2 font-headline text-uppercase">
                    <Link
                      href="/projects#Dorian concept"
                      to="/programm#Dorian concept"
                      className="text-white"
                    >
                      Dorian concept
                    </Link>
                  </div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5 hidden">19:30</div>
                  <div className="h2 font-headline">
                    <Link
                      href="/projects#Zeliha Dogusan"
                      to="/programm#Zeliha Dogusan"
                      className="text-white"
                    >
                      Zeliha Dogusan
                    </Link>
                  </div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5 hidden">19:30</div>
                  <div className="h2 font-headline text-uppercase">
                    <Link
                      href="/projects#Leep"
                      to="/programm#Leep"
                      className="text-white"
                    >
                      Leep
                    </Link>
                  </div>
                </div>
                <div className="d-flex mb-5 flex-column flex-sm-row">
                  <div className="h2 mr-5 hidden">19:30</div>
                  <div className="h2 font-headline">
                    <Link
                      href="/projects#Ornella Rodriguez"
                      to="/programm#Ornella Rodriguez"
                      className="text-white"
                    >
                      Ornella Rodriguez
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer timetable="true" />
        </Layout>
      </>
    );
  }
}

export default Timetable;
