import React from "react";
import { siteMetadata } from "../../gatsby-config";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Logo from "../components/logo";
import SocialLinks from "../components/social-links";
import CrWorldwide from "../components/cr-worldwide";
import Meta from "../components/meta";
import Footer from "../components/footer";

import "../../node_modules/bootstrap/scss/bootstrap.scss";
import "../scss/general.scss";
import "../scss/colors.scss";
import "../scss/fonts.scss";

class FourZeroFour extends React.Component {
  render() {
    const { location } = this.props;
    return (
      <>
        <Layout location={location}>
          <Meta
            site={siteMetadata}
            title="404"
            description="404"
            siteurl="404"
          />
          <main>
            <div className="position-relative main-hero">
              <Link href="/" to="/" className="link-no-styling">
                <Logo color="white" />
              </Link>
              <SocialLinks></SocialLinks>
              <CrWorldwide></CrWorldwide>
              <div className="main-title text-center">
                <div className="h1 text-fancy-gradient">
                  404 - Reality not found
                </div>
                <a className="h3" href="/">
                  zurück
                </a>
              </div>
            </div>
          </main>

          <Footer></Footer>
        </Layout>
      </>
    );
  }
}

export default FourZeroFour;
