import React from "react";

import "../../scss/general.scss";
import "../../scss/colors.scss";
import "../../scss/fonts.scss";
import "../../scss/index.scss";
import "./style.scss";

const Logo = ({ color, styleModifier }) => (
  <svg
    id={"logo-" + color}
    className={"logo " + styleModifier}
    data-name={"logo-" + color}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 748.61 736.76"
  >
    <title>CR_Logo</title>
    <path
      fill={color}
      d="M0,137.11C0,51.5,64.11,0,132.29,0,199,0,238.64,39.28,250.49,85.23L208.63,99.3c-7.78-31.86-32.24-57.42-76.34-57.42-43.35,0-86.71,31.49-86.71,95.23,0,61.51,42.24,94.12,87.08,94.12,44.1,0,69.29-28.17,78.18-57.81l40.77,13.34c-11.85,44.47-51.87,87.08-119,87.08C61.88,273.84,0,222.71,0,137.11Z"
    />
    <path
      fill={color}
      d="M616.79,462.92h-2c-67.94.39-131.56,51.83-131.56,137.12,0,85.61,61.85,136.72,132.67,136.72S748.61,685.65,748.61,600C748.61,514.62,684.79,463.18,616.79,462.92Zm-.85,231.23c-44.84,0-87.12-32.59-87.12-94.11,0-63.42,42.94-94.91,86-95.23h2C660,505.07,703,536.55,703,600,703,661.56,660.77,694.15,615.94,694.15Z"
    />
    <path
      fill={color}
      d="M608.91,164.15H576.29V267.91H531.46V5.18H632.25c50.77,0,82.27,35.21,82.27,79.67,0,37.42-22.6,66-59.3,74.86l73.58,108.2H678.4Zm15.19-39.28c27.43,0,45.22-15.93,45.22-40,0-24.46-17.79-40.39-45.22-40.39H576.29v80.41Z"
    />
    <path
      fill={color}
      d="M34.6,564.22a138.84,138.84,0,0,1-1.22-15c0-45.39,34-86.31,93.6-86.31,58,0,92.4,37.69,92.4,82.67,0,34-19,62-50.65,81.44l-51.07,31.6c-12.55,7.72-23.09,17-26.73,30H221v43h-190c.82-45,16.63-80.24,62.41-108.59l43.36-27.15c23.51-14.6,33.63-30.4,33.63-49.45,0-21.47-14.58-40.93-44.17-40.93-30.79,0-45.78,21.49-45.78,48.64,0,4,.81,8.92,1.22,13.37Z"
    />
  </svg>
);

export default Logo;
