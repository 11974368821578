import React from "react";
import "./style.scss";

import "../../scss/general.scss";
import "../../scss/colors.scss";
import "../../scss/fonts.scss";
import "../../scss/index.scss";

const ProgrammItem = ({
  title,
  description,
  additionalInfo,
  time,
  imgUrl,
  key
}) => (
  <div className="programm-item mb-5" key={key} id={title}>
    <div className="programm-item__content">
      <div className="row">
        <div className="col-1 col-lg-2">
          {time && <div className="h2 programm-time">ab {time}</div>}
        </div>
        <div className="col-11 col-md-5 col-lg-4">
          <div className="embed-responsive embed-responsive-1by1">
            <img
              src={imgUrl}
              className="embed-responsive-item programm-item__image"
              alt={title}
            ></img>
          </div>
        </div>
        <div className="col-11 col-md-6 offset-1 offset-md-0">
          {additionalInfo && (
            <div className="mb-3 p2 mt-2 mt-md-0">{additionalInfo}</div>
          )}
          <div className="mb-4 font-headline h4">{title}</div>
          <div className="p2">{description}</div>
        </div>
      </div>
    </div>
  </div>
);

export default ProgrammItem;
