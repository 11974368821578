import React from "react";
import { Link } from "gatsby";

import "../../scss/general.scss";
import "../../scss/colors.scss";
import "../../scss/fonts.scss";
import "./style.scss";

import FH from "../../../static/sponsors/fh.png";
import Astera from "../../../static/sponsors/Astera_Logo.png";
import Kaiser from "../../../static/sponsors/Kaiser_Showtechnik_Logo.png";
import Hills from "../../../static/sponsors/7hills_logo.png";
import EQC from "../../../static/sponsors/EQC_Logo.png";
import Kobe from "../../../static/sponsors/Kobe_Logo.png";

const Footer = ({ index, timetable, projects, lineup }) => (
  <div className="footer">
    <nav className="d-flex justify-content-around w-100 p-3">
      <Link className={index ? "nav-link active" : "nav-link"} href="/" to="/">
        Das Festival
      </Link>
      <Link
        className={timetable ? "nav-link active" : "nav-link"}
        href="/timetable"
        to="/timetable"
      >
        Timetable
      </Link>
      <Link
        className={lineup ? "nav-link active" : "nav-link"}
        href="/programm"
        to="/programm"
      >
        Speakers & Acts
      </Link>
      <Link
        className={projects ? "nav-link active" : "nav-link"}
        href="/projects"
        to="/projects"
      >
        Projekte
      </Link>
    </nav>
    <div className="container">
      <div className="py-md-5 py-3">
        <Link
          className="h3 text-white text-uppercase"
          href="/impressum"
          to="/impressum"
        >
          Impressum
        </Link>
        <div className="d-flex justify-content-between align-items-center">
          <div className="p1">
            Eine Veranstaltung in Kooperation mit <br /> der{" "}
            <strong>
              <a
                className="text-white"
                href="https://www.fh-salzburg.ac.at/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Fachhochschule Salzburg{" "}
              </a>
            </strong>
          </div>

          <a
            className="link-no-styling"
            href="https://www.fh-salzburg.ac.at/"
            to="https://www.fh-salzburg.ac.at/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="Logo FH Salzburg"
              height="80px"
              className="footer__sponsors__logo"
              src={FH}
            ></img>
          </a>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center my-5">
          <a
            className="link-no-styling"
            href="https://www.7hills.at/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="7hills"
              height="60px"
              className="footer__sponsors__logo"
              src={Hills}
            ></img>
          </a>
          <a
            className="link-no-styling"
            href="https://astera-led.com/de/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="Astera"
              height="100px"
              className="footer__sponsors__logo"
              src={Astera}
            ></img>
          </a>
          <a
            className="link-no-styling"
            href="https://www.kaiser-showtechnik.de/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="Kaiser Showtechnik"
              height="100px"
              className="footer__sponsors__logo"
              src={Kaiser}
            ></img>
          </a>
          <a
            className="link-no-styling"
            href="https://equipment.cafe/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="EQC"
              height="100px"
              className="footer__sponsors__logo"
              src={EQC}
            ></img>
          </a>
          <a
            className="link-no-styling"
            href="https://www.kobelight.at/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="Kobe"
              height="100px"
              className="footer__sponsors__logo"
              src={Kobe}
            ></img>
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
