import React from "react";
import "./style.scss";

import "../../scss/general.scss";
import "../../scss/colors.scss";
import "../../scss/fonts.scss";
import "../../scss/index.scss";

const ProjectItem = ({ team, description, imgUrl, name, subcategory, key }) => (
  <div className="col-md-6 col-lg-4 project-item mb-5" key={key}>
    <div className="project-item__content">
      <div className="mb-3 p2">{subcategory}</div>
      <div>
        <img src={imgUrl} className="project-item__image" alt={name}></img>
      </div>
      <div className="mt-3 mb-1 p3">{name}</div>
      <div className="p2 mb-3 headline-opacity">{team}</div>
      <div className="p2">{description}</div>
    </div>
  </div>
);

export default ProjectItem;
