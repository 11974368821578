import React from "react";
import { siteMetadata } from "../../gatsby-config";
import { Link } from "gatsby";
import "moment/locale/de";
import moment from "moment";

import SocialLinks from "../components/social-links";
import CrWorldwide from "../components/cr-worldwide";
import Layout from "../components/layout";
import Meta from "../components/meta";
import Logo from "../components/logo";
import ProjectItem from "../components/project-item";
import Footer from "../components/footer";

import "../scss/general.scss";
import "../scss/colors.scss";
import "../scss/fonts.scss";
import "../scss/index.scss";
import "../scss/projects.scss";
import "../../node_modules/bootstrap/scss/bootstrap.scss";

class Projects extends React.Component {
  constructor(props) {
    super(props);
    let projectsToShowDay1 = props.location.hash ? 100 : 3;
    let categoriesToShowDay1 = props.location.hash ? 100 : 1;
    let projectsToShowDay2 = props.location.hash ? 100 : 3;
    let categoriesToShowDay2 = props.location.hash ? 100 : 1;
    this.state = {
      filter: "all",
      projectsToShowDay1,
      categoriesToShowDay1,
      projectsToShowDay2,
      categoriesToShowDay2,
      hideButtonDay1: props.location.hash ? true : false,
      hideButtonDay2: props.location.hash ? true : false
    };
  }
  render() {
    const { location, data } = this.props;
    const groups = data.allDirectusProject.group;

    const projectsSliceDay1 = this.state.projectsToShowDay1;
    const categorySliceDay1 = this.state.categoriesToShowDay1;
    const projectsSliceDay2 = this.state.projectsToShowDay2;
    const categorySliceDay2 = this.state.categoriesToShowDay2;

    const dict_categories = {
      "Design & Web": { color: "text-green", time: "17:05 - 17:30" },
      Werbefilme: { color: "text-purple", time: "18:20 - 18:40" },
      Kurzfilme: { color: "text-purple", time: "20:15 - 23:00" },
      Game: { color: "text-orange", time: "17:05 - 17:30" },
      Installationen: { color: "text-green", time: "18:20 - 19:10" }
    };

    // Group the projects for each day in categories
    const projectsByDateAndCategorie = groups.reduce((arr, item) => {
      const projectsByCategory = item.edges.reduce((obj, { node }) => {
        const category = node.category;
        var filtered_category = Object.fromEntries(
          Object.entries(dict_categories).filter(
            ([key, value]) => key === category
          )
        );
        obj[category] = obj[category] || [];
        obj[category]["color"] = Object.values(filtered_category)[0].color;
        obj[category]["time"] = Object.values(filtered_category)[0].time;
        obj[category].push(node);
        return obj;
      }, {});
      arr.push({
        date: item.fieldValue,
        categories: Object.entries(projectsByCategory)
      });
      return arr;
    }, []);

    return (
      <Layout location={location}>
        <Meta
          site={siteMetadata}
          title="Projekte"
          description="Projekte"
          siteurl="projects"
        />
        <main>
          <div className="position-relative main-hero">
            <Link href="/" to="/" className="link-no-styling">
              <Logo color="white" />
            </Link>
            <SocialLinks />
            <CrWorldwide />
            <div className="main-title text-center">
              <h4 className="text-white small">Creativity Rules 2.0</h4>
              <div className="text-fancy-gradient decoration-headline">
                Jetzt live!
              </div>
              <h4 className="text-white small">26. & 27. März</h4>
              <h5 className="text-white">17-23 Uhr</h5>
            </div>
            <span className="main-title__arrow">↓</span>
          </div>

          <nav className="d-flex justify-content-around w-100 p-3">
            <Link className="nav-link" href="/" to="/">
              Das Festival
            </Link>
            <Link className="nav-link" href="/timetable" to="/timetable">
              Timetable
            </Link>
            <Link className="nav-link" href="/programm" to="/programm">
              Speakers & Acts
            </Link>
            <Link className="nav-link active" href="/projects" to="/projects">
              Projekte
            </Link>
          </nav>

          <div className="content-block">
            <div className="container">
              <div className="h3">Projekte</div>

              {projectsByDateAndCategorie.map((day, index) => {
                const all = day.categories;
                const date = moment(day.date).format("dddd, DoMMMM YYYY");

                all.sort((a, b) =>
                  a[1].time > b[1].time ? 1 : b[1].time > a[1].time ? -1 : 0
                );

                const category_slice =
                  index === 0 ? categorySliceDay1 : categorySliceDay2;
                const projects_slice =
                  index === 0 ? projectsSliceDay1 : projectsSliceDay2;

                let btnStyle;
                if (index === 0) {
                  btnStyle = this.state.hideButtonDay1
                    ? { display: "none" }
                    : {};
                } else {
                  btnStyle = this.state.hideButtonDay2
                    ? { display: "none" }
                    : {};
                }

                return (
                  <div key={date}>
                    <div
                      className={`h3 headline-opacity date-margin-bottom ${
                        index === 1 ? "date-margin-top" : ""
                      }`}
                    >
                      {date}
                    </div>

                    {all.slice(0, category_slice).map(item => {
                      return (
                        <div className="row my-5" key={item[0]} id={item[0]}>
                          <div className="col-2 col-md-1 col-lg-2">
                            <div className="h2 programm-time">
                              {item[1].time}
                            </div>
                          </div>
                          <div className="col-8 col-md-10 col-lg-9">
                            <div className="row">
                              {item[1].slice(0, projects_slice).map(project => {
                                return (
                                  <ProjectItem
                                    key={project.name}
                                    name={project.name}
                                    description={project.description}
                                    team={project.team}
                                    subcategory={project.subcategory}
                                    imgUrl={project.image.data.full_url}
                                  ></ProjectItem>
                                );
                              })}
                            </div>
                          </div>
                          <div className="col-1">
                            <div
                              className={`h4 font-headline project-category ${item[1].color}`}
                            >
                              {item[0]}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <button
                      className="h2 load-more align-items-center w-100 justify-content-center px-3"
                      style={btnStyle}
                      onClick={() => {
                        index === 0
                          ? this.setState({
                              projectsToShowDay1:
                                this.state.projectsToShowDay1 + 100,
                              categoriesToShowDay1:
                                this.state.categoriesToShowDay1 + 100,
                              hideButtonDay1: true
                            })
                          : this.setState({
                              projectsToShowDay2:
                                this.state.projectsToShowDay2 + 100,
                              categoriesToShowDay2:
                                this.state.categoriesToShowDay2 + 100,
                              hideButtonDay2: true
                            });
                      }}
                    >
                      {" "}
                      <i className="arrow-down mr-4"></i> alle Projekte ansehen
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </main>
        <Footer projects="true" />
      </Layout>
    );
  }
}

export default Projects;

export const projektItems = graphql`
  {
    allDirectusProject(sort: { order: ASC, fields: [date] }) {
      group(field: date) {
        fieldValue
        edges {
          node {
            category
            date
            description
            team
            name
            subcategory
            image {
              data {
                full_url
                url
              }
            }
          }
        }
      }
    }
  }
`;
