import React from "react";

import "../../scss/general.scss";
import "../../scss/colors.scss";
import "../../scss/fonts.scss";
import "../../scss/index.scss";
import "./style.scss";

const SocialLinks = ({ color, styleModifier }) => (
  <div className="social-links">
    <a className="link-no-styling" href="mailto:office@creativity-rules.at">
      @
    </a>
    <a
      className="link-no-styling"
      target="_blank"
      rel="noreferrer"
      href="https://www.facebook.com/events/422299515517979"
    >
      fb
    </a>
    <a
      className="link-no-styling"
      target="_blank"
      rel="noreferrer"
      href="https://www.instagram.com/creativityrules2.0"
    >
      ig
    </a>
    <a
      className="link-no-styling"
      target="_blank"
      rel="noreferrer"
      href="https://www.youtube.com/channel/UC52Nd8lbwo6aZAzqRNxoTmQ/featured"
    >
      yt
    </a>
  </div>
);

export default SocialLinks;
